import { getReq, postReq, putReq } from "@services/Api"

export const students = {
    getById: async (id) => { return await getReq('Student', '/Student', `/student/${id}`, true) },
    getFile: async (name) => { return await getReq('Student', '/StudentCourse', `/file/${name}`, true) },
    save: async (body) => { return await postReq('Student', '/Student', `student`, body, true) },
    update: async (body, id) => { return await putReq('Student', '/Student', `student/${id}`, body, true) },
    status: async (id, status) => { return await getReq('Student', '/Student', `status/${id}/${status}`, true) },
    list: async (search, status, page, take) => { return await getReq('Student', '/Student', `students?PageNumber=${page}&PageSize=${take}${search.length > 0 ? `&Search=${search}` : ''}${status.length > 0 ? `&Status=${status}` : ''}`, true) },
}

export const person = {
    perfil: async (body) => { return await postReq('Person', `/Person/student-perfil`, body, true) }
}

export const chat = {
    getSessionTasks: async (sessionResourcesId, userId) => { return await getReq('Chat', `/Chat/getTasks/${sessionResourcesId}/${userId}`, true) },
    getSessionChatContacts: async (sessionResourcesId) => { return await getReq('Chat', `/Chat/getChatContacts/${sessionResourcesId}`, true) },
    selectSessionChat: async (id) => { return await getReq('Chat', `/Chat/selectChat/${id}`, true) },
    sendSessionMsg: async (body) => { return await postReq('Chat', `/Chat/sendMsg`, body, true) },
}

export const ecommerce = {
    my_cart: async (studentId) => { return await getReq('Course', `/Cart/my-cart/${studentId}`, true) },
    categories: async () => { return await getReq('Course', `/Cart/categories`, true) },
    add_cart: async (studentId, courseId) => { return await getReq('Course', `/Cart/add-cart/${studentId}/${courseId}`, true) },
    add_wishlist: async (studentId, courseId) => { return await getReq('Course', `/Cart/add-wishlist/${studentId}/${courseId}`, true) },
    remove_wishlist: async (studentId, courseId) => { return await getReq('Course', `/Cart/remove-wishlist/${studentId}/${courseId}`, true) },
    remove_cart: async (studentId, courseId) => { return await getReq('Course', `/Cart/remove-cart/${studentId}/${courseId}`, true) },
    search_courses: async (body) => { return await postReq('Course', `/Cart/search-courses`, body, true) },
    finalizar_pagamento: async (body) => { return await postReq('Course', `/Cart/finalizar-pagamento`, body, true) }
}

export const courses = {
    list: async (studentId, search, status, page, take) => { return await getReq('Courses', `/StudentCourse/courses?studentId=${studentId}&PageNumber=${page}&PageSize=${take}${search.length > 0 ? `&Search=${search}` : ''}${status.length > 0 ? `&Status=${status}` : ''}`, true) },
    getById: async (id) => { return await getReq('Course', `/StudentCourse/course/${id}`, true) },
    getSessionsById: async (id, studentCourseId) => { return await getReq('Course', `/StudentCourse/sessions/${id}/${studentCourseId}`, true) },
    finalizar_sessao: async (id, studentCourseId) => { return await getReq('Course', `/StudentCourse/finalizar-sessao/${id}/${studentCourseId}`, true) },
    calendar: async (body) => { return await postReq('Course', `/StudentCourse/calendar`, body, true) },
    getFile: async (name) => { return await getReq('Course', `/StudentCourse/file/${name}`, true) },
    sondagens: async (sessionResourcesId, studentCourseId) => { return await getReq('Course', `/StudentCourse/sondagens/${sessionResourcesId}/${studentCourseId}`, true) },
    sondagens_save: async (body) => { return await postReq('Course', `/StudentCourse/sondagens`, body, true) },
    resource: async (id) => { return await getReq('Course', `/StudentCourse/resource/${id}`, true) },
    comment: async (body) => { return await postReq('Course', `/StudentCourse/comment`, body, true) },
    termo_aceite: async (id) => { return await getReq('Course', `/StudentCourse/termo-aceite/${id}`, true) },
    obter_termo_aceite: async (id) => { return await getReq('Course', `/StudentCourse/obter-termo-aceite/${id}`, true) },
    comments: async (studentCourseId, sessionResourcesId) => { return await getReq('Course', `/StudentCourse/comments/${studentCourseId}/${sessionResourcesId}`, true) },
    save_attachments_trabalho: async (body) => { return await postReq('Course', `/StudentCourse/attachments-trabalho`, body, true) },
    all_attachments_trabalho: async (studentCourseId, sessionResourcesId) => { return await getReq('Course', `/StudentCourse/attachments-trabalho/${studentCourseId}/${sessionResourcesId}`, true) },
    student_teste: async (sessionResourcesId, studentCourseId) => { return await getReq('Course', `/StudentCourse/student-teste/${sessionResourcesId}/${studentCourseId}`, true) },
    save_teste: async (body) => { return await postReq('Course', `/StudentCourse/student-teste`, body, true) },
    getStudentTesteById: async (sessionResourcesId, studentCourseId) => { return await getReq('Course', `/StudentCourse/prova/${sessionResourcesId}/${studentCourseId}`, true) },
    finalizar_teste: async (studentCourseId, sessionResourcesId, body) => { return await postReq('Course', `/StudentCourse/finalizar-student-teste/${sessionResourcesId}/${studentCourseId}`, body, true) },
    shop_courses: async (studentId, search, page, take) => { return await getReq('Courses', `/StudentCourse/shop-courses?studentId=${studentId}&PageNumber=${page}&PageSize=${take}${search.length > 0 ? `&Search=${search}` : ''}`, true) }
}
