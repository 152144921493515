// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { SessionApi } from '@services/api-routes'

// ** Axios Imports
import axios from 'axios'
import { handleLoading } from '../../../redux/loader'

export const getCourses = createAsyncThunk('appEcommerce/getCourses', async (params, { dispatch }) => {
  dispatch(handleLoading(true))
  const response = await SessionApi.ecommerce.search_courses(params)
  dispatch(handleLoading(false))
  return { params, data: response.payload }
})

export const addToCart = createAsyncThunk('appEcommerce/addToCart', async (params, { dispatch, getState }) => {
  const response = await SessionApi.ecommerce.add_cart(params?.studentId, params?.courseId)
  dispatch(handleLoading(true))
  await dispatch(getCartItems(params?.studentId))
  await dispatch(getCourses(getState().ecommerce.params))
  dispatch(handleLoading(false))
  return response.payload
})

export const getWishlistItems = createAsyncThunk('appEcommerce/getWishlistItems', async () => {
  const response = await axios.get('/apps/ecommerce/wishlist')
  return response.data
})

export const deleteWishlistItem = createAsyncThunk('appEcommerce/deleteWishlistItem', async (params, { dispatch, getState }) => {
  dispatch(handleLoading(true))
  const response = await SessionApi.ecommerce.remove_wishlist(params?.studentId, params?.courseId)
  await dispatch(getCourses(getState().ecommerce.params))
  dispatch(handleLoading(false))
  return response.payload?.id
})

export const getCartItems = createAsyncThunk('appEcommerce/getCartItems', async (studentId, { dispatch }) => {
  dispatch(handleLoading(true))
  const response = await SessionApi.ecommerce.my_cart(studentId)
  dispatch(handleLoading(false))
  return response.payload
})

export const getCourse = createAsyncThunk('appEcommerce/getCourse', async slug => {
  const response = await axios.get(`/apps/ecommerce/products/${slug}`)
  return response.data
})

export const addToWishlist = createAsyncThunk('appEcommerce/addToWishlist', async (params, { dispatch, getState }) => {
  dispatch(handleLoading(true))
  const response = await SessionApi.ecommerce.add_wishlist(params?.studentId, params?.courseId)
  await dispatch(getCourses(getState().ecommerce.params))
  dispatch(handleLoading(false))
  return response.payload?.id
})

export const deleteCartItem = createAsyncThunk('appEcommerce/deleteCartItem', async (params, { dispatch }) => {
  dispatch(handleLoading(true))
  const response = await SessionApi.ecommerce.remove_cart(params?.studentId, params?.courseId)
  await dispatch(getCartItems(params?.studentId))
  dispatch(handleLoading(false))
  return id
})

export const appEcommerceSlice = createSlice({
  name: 'appEcommerce',
  initialState: {
    cart: [],
    params: {},
    courses: [],
    wishlist: [],
    totalCourses: 0,
    coursetDetail: {}
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCourses.fulfilled, (state, action) => {
        state.params = action.payload.params
        state.courses = action.payload.data.items
        state.totalCourses = action.payload.data.totalItems
      })
      .addCase(getWishlistItems.fulfilled, (state, action) => {
        state.wishlist = action.payload.courses
      })
      .addCase(getCartItems.fulfilled, (state, action) => {
        state.cart = action.payload
      })
      .addCase(getCourse.fulfilled, (state, action) => {
        state.coursetDetail = action.payload.course
      })
  }
})

export default appEcommerceSlice.reducer
