// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import loader from './loader'
import chat from '@src/views/componentes/recurso-chat/store'
import ecommerce from '@src/views/shop/store'


const rootReducer = {
  auth,
  navbar,
  layout,
  loader,
  chat,
  ecommerce
}

export default rootReducer
