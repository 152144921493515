import { getReq, postReq, putReq } from "@services/Api"

export const session = {
  login: async (body) => { return await postReq('login', '/authAluno/login', body, false) },
  validaEmail: async (email) => { return await getReq('valida e-mail', `/authAluno/valida-email/${email}`, false) },
  sendToken: async (body) => { return await postReq('enviar token da twilio', '/authAluno/envia-token-twilio', body, false) },
  verifyToken: async (body) => { return await postReq('verificar token da twilio', '/authAluno/verifica-token-twilio', body, false) },
  refreshToken: async () => { return await postReq('reenviar token da twilio', '/authAluno/refresh-token', '', false) },
  register: async (body) => { return await postReq('cadastrar', '/authAluno/registrar', body, false) },
  recovery_password: async (body) => { return await postReq('recuperar senha', `/authAluno/forgot-password`, body, false) },
  validate_token: async (token) => { return await getReq('recuperar senha', `/authAluno/validate-forgot-password/${token}`, false) },
  new_password: async (body) => { return await postReq('nova senha', '/authAluno/change-password', body, false) }
}
